import type { BtnVariants, UiVariant } from '~/types';

const btnClassMap = (variant: BtnVariants) => {
  const mapping: Record<BtnVariants, string> = {
    primary: 'bg-brand-primary text-brand-white hover:bg-brand-primary/70 active:bg-brand-primary active:text-brand-white/80 disabled:bg-brand-primary/40 disabled:text-brand-white/80',
    secondary: 'bg-brand-secondary text-brand-white hover:bg-brand-secondary active:bg-brand-secondary active:text-brand-white/80',
    white: 'text-brand-black bg-brand-white hover:text-brand-white hover:bg-brand-primary',
    'primary-outlined': 'bg-transparent text-brand-black border border-brand-primary',
    'secondary-outlined': 'bg-transparent text-brand-white border border-brand-white',
    transparent: 'bg-transparent',
    tertiary: 'bg-brand-tertiary text-brand-black hover:bg-brand-tertiary/80',
    error: 'bg-brand-red text-brand-white',
    success: 'bg-brand-success text-brand-white',
    warning: 'bg-brand-warning text-brand-white',
    highlight: 'bg-brand-highlight text-brand-white',
    alternate: 'bg-brand-alternate',
    black: 'bg-black text-brand-white',
  };

  return mapping[variant];
};

const tagsClassMap = (variant: UiVariant) => {
  const mapping: Record<BtnVariants, string> = {
    primary: 'bg-brand-primary text-brand-white',
    secondary: 'bg-brand-secondary text-brand-white',
    white: 'text-brand-black bg-brand-white border border-brand-primary',
    'primary-outlined': 'bg-transparent text-brand-black border border-brand-primary',
    'secondary-outlined': 'bg-transparent text-brand-white border border-brand-white',
    transparent: 'text-brand-white',
    tertiary: 'bg-brand-tertiary text-brand-black',
    error: 'bg-brand-error text-brand-white',
    success: 'bg-brand-success text-brand-white',
    warning: 'bg-brand-warning text-brand-black',
    highlight: 'bg-brand-highlight text-brand-white',
    alternate: 'bg-brand-alternate',
    black: 'bg-black text-brand-white',
  };

  return mapping[variant];
};

const titlesClassMap = (variant: UiVariant) => {
  const mapping: Partial<Record<UiVariant, string>> = {
    primary: 'text-brand-black',
    secondary: 'text-brand-secondary',
    white: 'text-white',
    transparent: 'text-white',
  };

  return mapping[variant];
};

export function useThemeVariant () {
  return {
    btnClassMap,
    titlesClassMap,
    tagsClassMap,
  };
}
